import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get('module/kids_vaccination', {
            params: userData
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`module/famkids_vaccination/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDatavac(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`module/kids_vaccination/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('module/kids_vaccination', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    editData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`kids_vaccination/edit`,
            userData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/module/kids_vaccination/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    downloadDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/kids_vaccination/download/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`kids_vaccination/filedocument/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deletefileData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/kids_vaccination/filedocument/+${id}')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchefamilymemberdetails(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/children/relateddata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    familymemberData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`module/children/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchekidsvaccine(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/kids_vaccination/getalldata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
